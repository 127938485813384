<div class="brand-logo-filter container-laasy">

  <div class="brand-logo-filter-mobile" *appDeviceType="'mobile'">
    <ng-container [ngTemplateOutlet]="brandLogo" [ngTemplateOutletContext]="{isMobileView:true}"></ng-container>
  </div>

  <ng-container *appDeviceType="'desktop'">
    <app-wrapper-card-scroll [scrollDivClass]="'.l-circular-card-list'" #scroller>
      <div cardContainer>
        <ng-container [ngTemplateOutlet]="brandLogo"></ng-container>
      </div>
    </app-wrapper-card-scroll>
  </ng-container>


</div>

<ng-template #brandLogo let-isMobileView="isMobileView">
  <div #brandLogos class="l-circular-card-container" *ngIf="cardData?.length > 0">
    <div class="l-circular-card-list">
      <div class="l-circular-card" *ngFor="let item of cardData; let i = index">
        <div *ngIf="isDataLoaded" class="l-circular-card-image-container" [ngClass]="{'selected-ring':item.isSelected}" (click)="onCardClick(item,i)">
          <img *ngIf="item?.image" [src]="item?.image" alt="" class="l-circular-card-image"/>
          <span *ngIf="!item?.image" class="mobile-initial" >{{item.label | initials}}</span>
        </div>
        <div *ngIf="isDataLoaded" class="l-circular-card-text-container">{{item?.label}}</div>
        <p-skeleton shape="circle" size="7.5rem" *ngIf="!isDataLoaded" />
        <p-skeleton width="120px" height="12px" styleClass="mb-2" *ngIf="!isDataLoaded && !isMobileView" />
      </div>
    </div>
  </div>
</ng-template>

