import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CircularLogoLabelComponent } from '@laasy/common-layout/shop';
import { debounceTime, distinctUntilChanged, map, Subject, switchMap } from 'rxjs';
import { ShopByCategoryDirective } from '../../directives/shop-by-category.directive';
import { GoogleCategoryResultService } from '../../services/search/google-category-result.service';
import { SearchWithFilterService } from '../../services/search/search-with-filter.service';
import { SearchSelectedFilterService } from '../../services/search/search-selected-filter.service';
import { DeviceTypeDirective, WrapperCardScrollComponent } from '@laasy/common-layout';
import { SkeletonModule } from 'primeng/skeleton';
import { InitialsPipe } from '../../pipes/initials.pipe';

@Component({
  selector: 'app-brand-logo-filter',
  standalone: true,
  imports: [CommonModule, CircularLogoLabelComponent, ShopByCategoryDirective, DeviceTypeDirective, SkeletonModule, InitialsPipe,WrapperCardScrollComponent],
  templateUrl: './brand-logo-filter.component.html',
  styleUrls: ['./brand-logo-filter.component.scss']
})
export class BrandLogoFilterComponent implements OnInit {

  cardData: any = [];
  prevCardData: any = [];
  setBrandImages$: Subject<any> = new Subject<any>();
  googleCategoryResultService = inject(GoogleCategoryResultService);
  swfService = inject(SearchWithFilterService);
  ssfService = inject(SearchSelectedFilterService);
  multiSelecting$ = this.ssfService.multiSelecting$;
  cardSelect$ = new Subject<any>();
  isDataLoaded: boolean = false;
  @ViewChild('scroller') scroller!: ElementRef;

  ngOnInit(): void {
    const isMobile = window.innerWidth < 960;

    this.googleCategoryResultService.filterItems$.subscribe(async (data: any) => {
      if (data.length === 0) return;
      const brands = data.find((item: any) => item.label === "Brands")?.options.slice(0, 20);

      // same list with selection update
      if (this.prevCardData.length === brands.length && this.prevCardData.every((item: any, i: number) => item.label === brands[i].label)) {
        this.cardData.forEach((item: any, i: number) => {
          item.isSelected = brands[i].isSelected;
        });
      } else {
        this.cardData = [];

        this.cardData = brands.map((item: any) => ({
          label: item.label,
          image: null,
          isSelected: item.isSelected ?? false
        }));
        this.isDataLoaded = true;
        this.setBrandImages$.next(this.cardData);
      }
      const { cachedBrands } = this.swfService.getBrandLogoCache();
      this.cardData.forEach((item: any) => {
        item.image = cachedBrands.find((prevItem: any) => prevItem.label === item.label)?.image ?? null;
      });
      this.prevCardData = structuredClone(this.cardData);
    });

    this.setBrandImages$.pipe(
      debounceTime(100),
      distinctUntilChanged((prev, curr) => {
        const brands = prev.map((item: any) => item.image);
        const newBrands = curr.map((item: any) => item.image);
        return JSON.stringify(brands) === JSON.stringify(newBrands);
      }),
      switchMap((data: any) => {
        const brands = data.map((item: any) => item.label);
        return this.swfService.getBrandLogo(brands).pipe(
          map((brandData: any) => {
            return data.map((item: any) => {
              const brandItem = brandData.find((brand: any) => brand.label === item.label);
              if (brandItem && brandItem.image) item.image = brandItem?.image;
              return item;
            });
          })
        );
      })
    ).subscribe((updatedCardData: any) => {
      this.cardData = updatedCardData;
    });

    this.cardSelect$.pipe(debounceTime(isMobile ? 2000 : 200)).subscribe((data: any) => this.ssfService.multiSelecting$.next({ label: data.label, filterName: data.filterName }));

  }

  ngAfterViewInit(): void {
    if(this.scroller){
      const scrollerDiv = (this.scroller as any)?.mainContainer?.nativeElement;
      const leftArrow = scrollerDiv?.querySelector('.left-arrow-pos');
      const rightArrow = scrollerDiv?.querySelector('.right-arrow-pos');
      if(leftArrow && rightArrow){
        leftArrow.style.left = '0px';
        rightArrow.style.right = '0px';
        leftArrow.style.transform = 'translateX(50%)';
        rightArrow.style.transform = 'translateX(-50%)';
      }
    }
  }

  onCardClick(item:any,i:number){
    this.cardClicked(item);
  }

  cardClicked(data: any): void {
    const filterName = 'Brands';
    const filterItems = structuredClone(this.googleCategoryResultService.filterItems$.value);
    const category = filterItems.find((filter: any) => filter.label === filterName);
    const isChecked = category.options.find((option: any) => option.label === data.label).isSelected;
    category.options.find((option: any) => option.label === data.label).isSelected = !isChecked;
    if (category && !isChecked) {
      this.ssfService.addSelectedFiltersData(data.label, filterName);
    }
    this.ssfService.updateFilterClipList(filterItems);
    this.googleCategoryResultService.filterItems$.next(structuredClone(filterItems));
    this.updateRequestFilter(filterName, data.label, !isChecked);
    this.cardSelect$.next({ label: data.label, filterName: filterName });
  }

  updateRequestFilter(key: string, value: string, selected: boolean) {
    let filter = this.swfService.requestData.filter;
    if (!filter[key]) {
      filter[key] = []
    }
    if (selected)
      filter[key].push(value);
    else {
      filter[key] = filter[key].filter((item: string) => item !== value);
    }
  }

}
