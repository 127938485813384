import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { AuthorizedApiService, CommonService, getCookieDomain, getHostName, updateCSS, updateEnv, buildVersionCacheClear } from '@laasy/common-layout';
import { environment } from 'src/environments/environment';
import { Amplify } from 'aws-amplify';
import { Location } from '@angular/common';
import awsmobile from 'src/aws-exports';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from '../authentication/authentication.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  private isEnvUpdated = new BehaviorSubject<boolean>(false);

  constructor(private router:Router,private readonly authenticationService: AuthenticationService,private titleService: Title, private authapi: AuthorizedApiService, private location: Location, private commonService: CommonService) { }

  get isEnvUpdated$(): Observable<any>{
    return this.isEnvUpdated.asObservable();
  }

  envUpdated() : void{
    this.isEnvUpdated.next(true);
  }

  getTenantContext(): Observable<any> {
    return this.commonService.getTenantContext();
  }

  async getTenantAppConfigPromise() {
    try {
      buildVersionCacheClear();
      const res: any = await this.authapi.getAndCacheWithLocalAsync(`/tenant/api/admin/tenantsbydomain/${window.location.hostname}`);
      if (res?.data?.length === 0) return new Error('No Tenant Found')
      this.tenantCustomization(res)

      const currentSession = await this.authenticationService.GetCurrentSession();

      const cognitoClientID = res?.data[0].clientId
      this.commonService.cacheCurrentUser(cognitoClientID)
      return res
    } catch (error) {
      this.router.navigateByUrl('/support/error')
      return error
    }
  }

  getTenantAppConfig(): Observable<any> {
    return this.commonService.getCustomization().pipe(
      map(res => {
        if (res?.data?.length === 0) throw new Error('No Tenant Found')
        const data = this.tenantCustomization(res)
        return data
      }),
      catchError((error: any) => {
        return throwError(() => error)
      })
    );
  }

  tenantCustomization(res: any) {
    const data = res.data[0];

    const favIcon: HTMLBaseElement | null = document.querySelector('#appIcon')
    if(data?.favIcon) favIcon!.href = data?.favIcon;

    if (data?.title) this.titleService.setTitle(data?.title);

    if (data?.homeAppUrl) localStorage.setItem("HomeAppURL",data.homeAppUrl);

    let cookieDomain = getCookieDomain(getHostName(this.location))
    if (data.externalDomain) cookieDomain = '.' + data.externalDomain

    const context = updateEnv(data, environment, awsmobile, this.location);
    updateCSS(data);
    for (const key in context.environment) {
      // eslint-disable-next-line no-prototype-builtins
      if (environment.hasOwnProperty(key)) (environment as any)[key] = context.environment[key];
    }
    Amplify.configure({ ...context.amplify, cookieStorage: { ...awsmobile['cookieStorage'], domain: cookieDomain } });

    return data
  }
}

