import { Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { PageNotFoundComponent, ErrorPageComponent } from '@laasy/common-layout/common-components';
import { LandingComponent } from './pages/landing/landing.component';
import { SearchResultComponent } from './pages/search-result/search-result.component';
import { CollectionResultComponent } from './pages/collection-result/collection-result.component';
import { PostPurchaseComponent } from './pages/post-purchase/post-purchase.component';
import { categoryResultPage } from './shared/utilities/constants';
import { GoogleCategoryResultComponent } from './pages/google-category-result/google-category-result.component';
import { OldSearchResultComponent } from './pages/old-search-result/old-search-result.component';
import { TenantService } from './shared/services/tenant/tenant.service';
import { ProductDetailsPageComponent } from './shared/components/product-details-page/product-details-page.component';


export const ROUTES: Routes = [
  { path: '', component: LandingComponent, canActivate: [AuthGuard] },
  { path: categoryResultPage.route, component: GoogleCategoryResultComponent, canActivate: [AuthGuard] },
  { path: 'collections/:collectionType', component: CollectionResultComponent, canActivate: [AuthGuard] },
  { path: 'support/error', component: ErrorPageComponent },
  // this is dummy page (not visible to user) used by home app to stay in shop on clear cache
  { path: 'clear-cache', component: PageNotFoundComponent },
  { path: 'post-purchase', component: PostPurchaseComponent, canActivate: [AuthGuard] },
  { path: 'product-details', component: ProductDetailsPageComponent, canActivate: [AuthGuard]},
  { path: '**', component: PageNotFoundComponent, data: { showFAQ: false }, canActivate: [AuthGuard] },
];

export async function dynamicRoutesFactory(apiService: TenantService) {
  const api = await apiService.getTenantAppConfigPromise()
  const hasGoogleCategorization = api.data[0].hasGoogleCategorization;
  const routes = [{
    path: 'search-result',
    component: hasGoogleCategorization ? SearchResultComponent : OldSearchResultComponent,
    canActivate: [AuthGuard]
  }];

  return routes
}
