<div *ngIf="isDataLoaded">
<div *appDeviceType="'desktop'">
<app-search-section></app-search-section>
 <app-breadcrumb [page]="pageConstants.route" [additionalQueryParams]="additionalQueryParams"  [multiLevel]="breadcrumbData"></app-breadcrumb>
  <div class="l-container" *ngIf="productDetails as prod">
  <div class="top-section">
    <div class="img-container">
      <img *ngIf="prod.imageUrl; else noProdImageUrl" [src]="prod.imageUrl" />
      <ng-template #noProdImageUrl>
        <img src="../../../../assets/images/missing-img-placeholder.png"/>
      </ng-template>
      <app-favourite-icon [item]="prod"></app-favourite-icon>
    </div>
    <div class="product-info">
      <div class="brand-name">{{prod.brandName}}</div>
      <div class="product-name">{{prod.productName}}</div>
      <div
        *ngIf="isDropshipSku"
        class="direct-checkout">
          Direct Checkout
        <span>
          <img src="../../../../assets/icons/checkout-info.svg" alt="" [pTooltip]="tooltipText" tooltipPosition="top"/>
        </span>
      </div>
      <div class="price">
        <span>{{prod.price | currency }}</span>
    </div>
    <div *ngIf="prod.cashback > 0"  class="d-flex flex-row">
      <app-cashback-chip [cashback]="prod.cashback"></app-cashback-chip>
      <span  *ngIf="prod.percentageCashback > 0" class="percentageCashback">{{prod.percentageCashback | price:true }}% cashback</span>
    </div>
    <!-- TODO: Get these available items and shipping details from the API -->
    <!-- <div class="stock-shipping">
      <img class="stock-icon" src="../../../../assets/icons/stock-icon.svg" alt="Stock Icon" />
      <span> 65 Items available</span>
      <img class="ml-12" src="../../../../assets/icons/shipping-icon.svg" alt="Shipping Icon" />
      <span class="ml-5 top-3">Free shipping</span>
    </div> -->

    <ng-container *ngIf="isActive;else unavailable">
      <ng-container [ngTemplateOutlet]="activateOfferButton" [ngTemplateOutletContext]="{prod:prod}"></ng-container>
      <ng-container [ngTemplateOutlet]="merchantInfo" [ngTemplateOutletContext]="{prod:prod}"></ng-container>
    </ng-container>

    <ng-container [ngTemplateOutlet]="productDetailTemplate" [ngTemplateOutletContext]="{prod:prod}"></ng-container>

    </div>
  </div>
  <!-- <div class="like-text">You may also like</div> -->
  <!-- <app-shop-collection [title]="''" [collectionName]="''" [featuredData]="prod.similarProducts" [isDataLoaded]="true"></app-shop-collection> -->
</div>
</div>

<!-- mobile web  -->
<div *appDeviceType="'mobile'">
  <div class="mobile-container" *ngIf="productDetails as prod">
    <div class="back-btn" (click)="goBack()">
      <img src="../../../../assets/icons/back.svg" alt=""/>
      <span class="back-text">Back</span>
    </div>
    <div class="brand-name">{{prod.brandName}}</div>
    <div class="product-name">{{prod.productName}}</div>
    <div
        *ngIf="isDropshipSku"
        class="direct-checkout">
          Direct Checkout
          <img src="../../../../assets/icons/checkout-info.svg" alt="" [pTooltip]="tooltipText" tooltipPosition="top"/>
      </div>
      <div>
        <div class="img-fav-icon-container">
          <img  *ngIf="prod.imageUrl; else noProdImageUrl" [src]="prod.imageUrl" />
          <ng-template #noProdImageUrl>
            <img src="../../../../assets/images/missing-img-placeholder.png"/>
          </ng-template>
          <div class="top-right">
            <app-favourite-icon [item]="prod"></app-favourite-icon>
          </div>
        </div>
      </div>
      <div class="price">
        <span>{{prod.price | currency }}</span>
    </div>
    <div *ngIf="prod.cashback > 0"  class="d-flex flex-row">
      <app-cashback-chip [cashback]="prod.cashback"></app-cashback-chip>
      <span  *ngIf="prod.percentageCashback > 0" class="percentageCashback">{{prod.percentageCashback | price:true }}% cashback</span>
    </div>

    <ng-container *ngIf="isActive;else unavailable">
      <ng-container [ngTemplateOutlet]="activateOfferButton" [ngTemplateOutletContext]="{prod:prod}"></ng-container>
      <ng-container [ngTemplateOutlet]="merchantInfo" [ngTemplateOutletContext]="{prod:prod}"></ng-container>
    </ng-container>

    <ng-container [ngTemplateOutlet]="productDetailTemplate" [ngTemplateOutletContext]="{prod:prod}"></ng-container>

  </div>
</div>
</div>

<ng-template #activateOfferButton let-prod="prod">
  <div *ngIf="!isDropshipSku" (click)="activateOffer(prod.affiliateLink)" class="checkout-btn">
    <p-button [loading]="prod?.isLoading" label="Activate Offer">
      <svg class="activate-icon" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.5 2H22.5V8" stroke="var(--l-primary-tenant-button-text)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.5 13L22.5 2" stroke="var(--l-primary-tenant-button-text)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19.5 11.5333V17.1333C19.5 17.6284 19.3033 18.1032 18.9533 18.4533C18.6032 18.8033 18.1284 19 17.6333 19H7.36667C6.8716 19 6.3968 18.8033 6.04673 18.4533C5.69667 18.1032 5.5 17.6284 5.5 17.1333V6.86667C5.5 6.3716 5.69667 5.8968 6.04673 5.54673C6.3968 5.19667 6.8716 5 7.36667 5H12.9667" stroke="var(--l-primary-tenant-button-text)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </p-button>
  </div>

  <div *ngIf="isDropshipSku" (click)="activateOffer(prod.affiliateLink)" class="checkout-btn">
    <p-button>Proceed to Checkout</p-button>
  </div>
</ng-template>

<ng-template #unavailable>
  <div class="checkout-btn">
    <p-button [disabled]="true" class="unavailable">Unavailable</p-button>
  </div>
  <div class="merchant-info">
    <div class="merchant" style="margin: 0px;">
      <div class="merchant-name">This product is unavailable</div>
      <div class="redirect-text">This product is either out of stock or no longer available. Please check back later.</div>
    </div>
  </div>
</ng-template>

<ng-template #merchantInfo let-prod="prod">
  <div *ngIf="!isDropshipSku" class="merchant-info">
    <img [src]="prod.merchantLogoUrl" alt="" class="merchant-logo-url" />
    <div class="merchant">
      <div class="merchant-name">Continue to {{prod.websiteName}}</div>
      <div class="redirect-text">You will be redirected to the retailer’s site to complete your purchase. To receive cashback for this item, you must buy through this link.</div>
    </div>
  </div>
</ng-template>


<ng-template #productDetailTemplate let-prod="prod">
  <div *ngIf="prod.details" class="text-uppercase prod-detail-text">Product Detail</div>
  <div #prodDetails class="details-container" [class.expanded]="isProdDetailsExpanded" [innerHTML]="prod.details"></div>
  <div *ngIf="isProdDetailsOverflowing" class="show-more" (click)="toggleText()">
    {{ isProdDetailsExpanded ? 'Show less' : 'Show more' }}
  </div>
</ng-template>

