import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { SearchWithFilterService } from '../../services/search/search-with-filter.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged, firstValueFrom, of, Subject, Subscription, switchMap } from 'rxjs';
import { SliderModule } from 'primeng/slider';
import { categoryResultPage, filterOptionsToShow } from '../../utilities/constants';
import { SearchSelectedFilterService } from '../../services/search/search-selected-filter.service';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';
import { getPriceRange } from '../../utilities/helpers';
import { SortByComponent } from '../sort-by/sort-by.component';
import { FilterItem, SearchResponse } from '../../models/Search';
import { DeviceTypeDirective, sortItems } from '@laasy/common-layout';
import { SearchFilterPipe } from '../../pipes/search-filter.pipe';
import { FilterChipsComponent } from './filter-chips/filter-chips.component';
import { filterActionList } from './constant';
import { GoogleCategoryResultService } from '../../services/search/google-category-result.service';
import { SeeMoreOptionsPipe } from '../../pipes/see-more-options.pipe';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-google-filters',
  standalone: true,
  imports: [CommonModule, CheckboxModule, RadioButtonModule, FormsModule, SliderModule, SearchFilterPipe,FilterChipsComponent,BottomSheetComponent,DeviceTypeDirective, SidebarModule,ButtonModule, SeeMoreOptionsPipe ],
  templateUrl: './google-filters.component.html',
  styleUrls: ['./google-filters.component.scss']
})
export class GoogleFiltersComponent implements OnInit, OnDestroy {

  showClear: boolean = false;
  filterItems !: FilterItem[];
  priceRange: number[] = [];
  minPrice = 0;
  maxPrice !: number;
  priceChange$ = new Subject<any>();
  @Input() searchResponse!: SearchResponse;
  @Input() filterType!: string;


  selectedCategory:string|null = '';
  nextSelectedCategory = '';
  secondHighestLevelCategory = '';
  isGoogleCategory = false;
  filterItemsGoogleCategory !:FilterItem [];

  subsList !: Subscription[];
  pagePath:string = '';
  cacheFilterCount:any = {};
  showPriceFilter = true;

  // mobile
  sidebarVisible = false;
  userActionPrice: any = {};

  @ViewChild('bSheet') bSheet !: BottomSheetComponent;
  @ViewChild('sortBy') sortBy !: SortByComponent;

  constructor(
    private swfService: SearchWithFilterService,
    private router: Router,
    private route: ActivatedRoute,
    private ssfService: SearchSelectedFilterService,
    private googleCategoryResultService:GoogleCategoryResultService
  ){}

  ngOnInit(): void {
    const isMobile = window.innerWidth < 960;
    this.pagePath = this.router.url.split('?')[0]
    this.subsList = [
      this.ssfService.getFilterList$().subscribe((data) => {
          this.setFilterList(data);
          this.sidebarVisible = false;
      }),
      this.ssfService.showClear$.subscribe( (data) => {
        this.showClear = data;
        // update in filter - invalidate count cache
        this.cacheFilterCount = {}
      }),
      this.priceChange$.pipe(debounceTime(isMobile ? 0 : 750),distinctUntilChanged()).subscribe({
        next: (data:any) => (data?.id)? this.triggerPriceSearch(data.initialCondition,data.min,data.max,data.id):null
      }),

      this.ssfService.multiSelecting$.pipe(debounceTime(isMobile ? 200 : 2000)).subscribe(() => this.swfService.SearchWithExistingQuery()),
      this.googleCategoryResultService.filterChipAction$.subscribe((data) => {
        this.filterChipsAction(data);
      }),
      this.googleCategoryResultService.filterItems$.subscribe((data) => {
        this.filterItems = data.filter((item) => this.nextSelectedCategory !== item.label)
        this.filterItemsGoogleCategory= data.filter((item) => this.nextSelectedCategory === item.label)
        // update in filter - invalidate count cache
        this.cacheFilterCount = {};
      })
    ]
  }

  async setFilterList(filterLists: any){

    const list = structuredClone(filterLists);
    if(!list || Object.keys(list).length === 0) return;

    const exception = ['Cashbacks','Price','Colors', 'Sizes'];
    const qParams = await firstValueFrom(this.route.queryParams)
    let _filterItemList: FilterItem[] = [];
    let _googleFilterItemList: FilterItem[] = []


    this.selectedCategory = this.ssfService.getCategoryKey(qParams);
    this.nextSelectedCategory = this.selectedCategory
      ? categoryResultPage.queryParams[categoryResultPage.queryParams.indexOf(this.selectedCategory) + 1]
      : categoryResultPage.queryParams[0];
    this.secondHighestLevelCategory = (this.selectedCategory) ? list[this.selectedCategory][0]?.item : categoryResultPage.queryParams[0]; //for search result page category and one level --
    this.isGoogleCategory = true;

    Object.keys(list).forEach((key) => {
      if(!exception.includes(key)){
        _filterItemList.push({
          label: key,
          options: list[key].map((obj: {count: number, item: string}) =>({
            label: obj.item,
            isSelected: this.swfService.requestData.filter[key]?.includes(obj.item),
            count: obj.count
          })),
          isOpen: false,
          showClear: false,
          multiSelect: true,
          isSelected: false,
          noOfOptionsToShow: filterOptionsToShow,
          searchText: ''
        })
      }
    });
    if(list['Price']?.length){
      _filterItemList.push({
        label: 'Price',
        options: [],
        isOpen: false,
        showClear: false,
        multiSelect: false,
        isSelected: false,
        noOfOptionsToShow: filterOptionsToShow,
        searchText: ''
      })
      this.priceRange = getPriceRange(list['Price']);
      [this.minPrice,this.maxPrice] = this.priceRange;
    }

    _filterItemList.forEach((item:any) => {
      item.options = sortItems(item.options, 'count', true);
    });

    if(this.isGoogleCategory){
      const categoryLevelToHide = categoryResultPage.queryParams
      _googleFilterItemList = _filterItemList.filter((item) => this.nextSelectedCategory === item.label)
      _googleFilterItemList[0].multiSelect = this.nextSelectedCategory === categoryLevelToHide.slice(-1)[0];
      _filterItemList = _filterItemList.filter((item) => !categoryLevelToHide.includes(item.label))
    }
    this.googleCategoryResultService.filterItems$.next(_filterItemList.concat(_googleFilterItemList));
    this.ssfService.updateFilterClipList(_filterItemList.concat(_googleFilterItemList))
    if(this.swfService.showPriceChip) {
      this.ssfService.addPriceFilterChip(this.minPrice,this.maxPrice)
    }

    this.showPriceFilter = Number.isInteger(this.minPrice) && Number.isInteger(this.maxPrice) && this.minPrice >= 0 && this.maxPrice >= 0;
  }

  toggleFilterOptions(filterObj: any,lookupArray: any) {
    const category = this.findItem(filterObj.label, lookupArray)
    if (category) {
      filterObj.isSelected = !filterObj.isSelected
      category.isOpen = !category.isOpen
    }
  }

  findItem(filterName: string, lookupArray: any) {
    return lookupArray.find((itm: any) => itm.label == filterName)
  }

  onFilterCheckboxClick(label: string, filterName: string, isChecked: boolean, isMobile:boolean): void {
    const filterItems = this.filterItems.concat(this.filterItemsGoogleCategory);
    const category = filterItems.find(filter => filter.label === filterName);
    if(category && isChecked){
        this.ssfService.addSelectedFiltersData(label, filterName);
    }
    this.ssfService.updateFilterClipList(filterItems);
    this.googleCategoryResultService.filterItems$.next(structuredClone(filterItems));
    if(!isMobile) this.triggerSearch(label, filterName);
  }

  triggerSearch(label:string,filterName:string): void {
    this.ssfService.multiSelecting$.next({label:label, filterName:filterName});
  }

  triggerSearchOnMobile(): void {
    const filterItems = this.filterItems.concat(this.filterItemsGoogleCategory);
    this.priceChange$.next(this.userActionPrice);
    filterItems.forEach((filter) => {
      const selectedOptions = filter.options.filter((opt) => opt.isSelected);
      selectedOptions.forEach((opt) => {
        this.triggerSearch(opt.label, filter.label);
      });
    })
  }

  clearSearch(filterObj: any): void {
     filterObj.searchText = '';
  }

  onSearchTextChange(event: Event, filterObj: any): void {
    const value = (event.target as HTMLInputElement).value;
    filterObj.searchText = value;
  }

  updateRequestFilter(key: string, value: string,selected: boolean){
    let filter = this.swfService.requestData.filter;
    if(!filter[key]){
      filter[key] = []
    }
    if(selected)
      filter[key].push(value);
    else{
      filter[key] = filter[key].filter((item: string) => item !== value);
    }
  }


  onPriceChange(event: any,triggerPriceSearch:boolean = false){
    const Price = 'Price'
    const { values: [val1,val2] } = event;
    const min = Math.min(val1,val2);
    const max = Math.max(val1,val2);

    const initialCondition = min === this.minPrice && max === this.maxPrice;
    const val = {
      id:Price,
      min:min,
      max:max,
      initialCondition: initialCondition,
      searchTriggered: triggerPriceSearch
    }
    this.showClear = true
    this.userActionPrice = val;
    if(triggerPriceSearch) this.priceChange$.next(val);
    return val;
  }

  triggerPriceSearch(initialCondition:boolean,min:number,max:number,id:string){
    // change trigger by user
    this.swfService.showPriceChip = true;
    if(initialCondition){
      this.resetPrice();
    }else{
      this.swfService.setPriceRange(min, max);
    }
    this.swfService.SearchWithExistingQuery();
    if(!initialCondition){
      this.ssfService.removePriceChip();
      this.ssfService.addPriceFilterChip(min,max);
    }
  }

  resetPrice(){
    this.priceRange = [this.minPrice,this.maxPrice];
    this.swfService.setPriceRange(0,0);
  }

  sortFilterOptions(array: any) {
    const sortedData = array.sort((a:any, b:any) =>
      a.label.localeCompare(b.label)
    );
    return sortedData;
  }

  openFilterSection() {
    this.sidebarVisible = true;
  }

  closeFilterSection() {
    this.sidebarVisible = false;
  }

  clearAllFilters(){
    if(!this.filterItems) {
      return
    }
    this.swfService.showPriceChip = false;
    const filterItems = this.filterItems.concat(this.filterItemsGoogleCategory);
    for(let fliterObj of filterItems){
      for(let opt of fliterObj.options){
        opt.isSelected = false;
      }
    }
    this.swfService.resetFilter(this.isGoogleCategory && this.pagePath.includes(categoryResultPage.route));
    this.swfService.resetPageNumber();
    this.ssfService.updateSelectedFiltersData([]);
    this.resetPrice();

    // apply featured brand filter
    const qParams = this.route.snapshot.queryParams;
    if(qParams['Brands']){
      this.swfService.requestData.filter.Brands = [qParams['Brands']]
    }
    this.swfService.SearchWithExistingQuery();
  }

  setPriceRange(price: number | null, type: 'min' | 'max'){
    if(price === null) return;

    const [val1, val2] = this.priceRange;
    const min = Math.min(val1,val2);
    const max = Math.max(val1,val2);

    if(price < this.minPrice){
      price =  this.minPrice;
    }
    if(price > this.maxPrice){
      price =  this.maxPrice;
    }
    switch (type) {
      case 'min':
        if(price > max) price = max;
        this.priceRange = [price, max];
      break;
      case 'max':
        if(price < min) price = min;
          this.priceRange = [min,price];
      break;
    }
  }

  ngOnDestroy(): void {
    this.subsList.forEach(sub => sub?.unsubscribe());

  }

  onFilterCategoryClick(obj: any){
    const paramKey = this.filterItemsGoogleCategory[0].label
    const paramValue = obj.label;
    const queryParams = { ...this.route.snapshot.queryParams,
      [paramKey]: paramValue
    };
    // this.router.navigate([], { relativeTo: this.route, queryParams: queryParams });
    this.router.navigate([categoryResultPage.route], { queryParams:queryParams });
  }

  filterChipsAction(e:any){
    if(e === filterActionList.priceReset){
      this.resetPrice();
    }else if(e === filterActionList.clearAll){
      this.clearAllFilters();
    }
  }

  triggerPriceRange$(e:any,type:any,isMobile:boolean){
    this.setPriceRange(e,type);
    const val = this.onPriceChange({values: this.priceRange});
    if(isMobile){
      return;
    }
    if(!val.searchTriggered)
      this.priceChange$.next(val);
  }

  focus(event:any){
    event.preventDefault();
  }

  showSeeMore(filterObj: any):boolean {
    if(filterObj.label === 'Brands'){
        return filterObj.options.length > filterObj.noOfOptionsToShow && !filterObj.searchText;
    } else {
        return false;
    }
  }

  seeMoreOptions(filterObj?: any):void {
    filterObj.noOfOptionsToShow = filterObj.noOfOptionsToShow + filterOptionsToShow;
  }

  getSelectedFilterCount(filterName:string) {
    if (!Object.keys(this.cacheFilterCount).includes(filterName)) {
      const filter = this.filterItems.concat(this.filterItemsGoogleCategory).find((item) => item.label === filterName);
      this.cacheFilterCount[filterName] = filter?.options.filter((opt) => opt.isSelected).length ?? 0;
    }
    return this.cacheFilterCount[filterName];
  }
}
