import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials',
  standalone: true
})
export class InitialsPipe implements PipeTransform {

  transform(value: string): unknown {
    if(!value) return '';
    return value.split(' ').map((word:string) => word[0].toUpperCase()).join('');
  }

}
