import { Injectable } from "@angular/core";
import { BehaviorSubject, filter, Subject } from "rxjs";
import { FilterChip } from "../../models/Search";
import { categoryResultPage } from "../../utilities/constants";
import { SearchWithFilterService } from "./search-with-filter.service";


@Injectable({
  providedIn: 'root'
})
export class SearchSelectedFilterService {

  filterChips$ = new BehaviorSubject<FilterChip[]>([]);
  filterChipList: FilterChip[] = [];
  showClear$ = new BehaviorSubject<boolean>(false);
  setFilterList$ = new BehaviorSubject<any>(null);
  multiSelecting$ = new Subject<any>();

  constructor(private swfService: SearchWithFilterService) {
    this.filterChips$.subscribe((data) => {
      this.showClear$.next(data.length > 0);
    })
  }

  addSelectedFiltersData(label: string, filterName: string, isCategory?: boolean | false): void{
    let isSelected =  this.filterChipList.some(res => res.label === label)
    if(!isSelected) {
      if(isCategory){
        this.updateSelectedFiltersData([]);
      } else{
        this.addFilterChip(label, filterName);
      }
    }
  }

  addPriceFilterChip(min: number, max: number): void {
    this.removePriceChip();
    if(min !==undefined && max !==undefined && min !== null && max !== null){
      this.addSelectedFiltersData(`$${min} - $${max}`,'Price');
    }
  }

  updateFilterClipList(filterItems:any){
    const selectedFilterChips:FilterChip[] = []
    filterItems.forEach((filterItem:any) => {
      filterItem.options.forEach((option:any) => {
        if(option.isSelected){
          selectedFilterChips.push({label: option.label, filterName: filterItem.label})
        }
      })
    })
    this.updateSelectedFiltersData(selectedFilterChips);
  }

  addFilterChip(label:string, filterName:string){
    this.filterChipList.push({label: label, filterName: filterName});
    this.filterChips$.next(this.filterChipList);
  }

  removeFilterChip(label: string, filterName: string) {
    this.filterChipList = this.filterChipList.filter(res => !(res.label === label && res.filterName === filterName));
    this.filterChips$.next(this.filterChipList);
  }

  removePriceChip() {
    const chips = this.filterChipList.filter(
      (chip:any) => chip.filterName !== 'Price'
    )
    this.updateSelectedFiltersData(chips);
  }

  updateSelectedFiltersData(filterList: any): void{
    this.filterChipList = filterList;
    this.filterChips$.next(this.filterChipList)
  }

  showClearButton(show: boolean): void {
    this.showClear$.next(show);
  }

  setFilterList(list:any){
    this.setFilterList$.next(list)
  }

  getFilterList$() {
    return this.setFilterList$.pipe(filter((data) => data && (Object.keys(data).length > 0) && JSON.stringify(data) === JSON.stringify(this.swfService.responseData.aggregations.filters)));
  }

  getCategoryKey(qParams:any){
    const queryParamKeys = categoryResultPage.queryParams;
    let highestIndex = -1;

    Object.keys(qParams).forEach((key:string) => {
      if (queryParamKeys.includes(key) && queryParamKeys.indexOf(key) > highestIndex)
        highestIndex = queryParamKeys.indexOf(key);
    });
    if(highestIndex === -1)
      return null
    return queryParamKeys[highestIndex]
  }

  initializeFiltersFromQueryParams(qParams:any) {
    this.updateSelectedFiltersData([]);
    const {reqDataFilter, activeCategory} = this.swfService.getCategoriesLevelSelection(qParams);
    this.swfService.removeCategoryLevels();
    this.swfService.featuredSearch(reqDataFilter);
    return activeCategory;
  }
}
