import { ChangeDetectorRef, Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchSectionComponent } from 'src/app/shared/components/search-section/search-section.component';
import { DeviceTypeDirective, mapDataToSkuCard } from '@laasy/common-layout';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { SearchService } from 'src/app/shared/services/search/search.service';
import { categoryResultPage, SearchConst, searchDefaultSort } from 'src/app/shared/utilities/constants';
import { SearchResponse } from 'src/app/shared/models/Search';
import { BottomSheetComponent } from 'src/app/shared/components/bottom-sheet/bottom-sheet.component';
import { SortByComponent } from 'src/app/shared/components/sort-by/sort-by.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MenuModule } from 'primeng/menu';
import { SearchWithFilterService } from 'src/app/shared/services/search/search-with-filter.service';
import { Subscription } from 'rxjs';
import { SliderModule } from 'primeng/slider';
import { ActivatedRoute, Router } from '@angular/router';
import { LandingService } from 'src/app/shared/services/Page/landing.service';
import { BreadcrumbComponent } from 'src/app/shared/components/breadcrumb/breadcrumb.component';
import { SortBy } from 'src/app/shared/models/sortBy';
import { environment } from 'src/environments/environment';
import { SearchSectionService } from 'src/app/shared/services/search/search-section.service';
import { EmptyStateDisplayComponent } from 'src/app/shared/components/empty-state-display/empty-state-display.component';
import { TruncatePipe } from 'src/app/shared/pipes/truncate.pipe';
import { ProgressBarModule } from 'primeng/progressbar';
import { ShopModule } from '@laasy/common-layout/shop';
import { CommonComponentModule } from '@laasy/common-layout/common-components';
import { FiltersComponent } from "../../shared/components/filters/filters.component";
import { SearchSelectedFilterService } from 'src/app/shared/services/search/search-selected-filter.service';
import { GoogleFiltersComponent } from 'src/app/shared/components/google-filters/google-filters.component';
import { StylePaginationDirective } from 'src/app/shared/directives/style-pagination.directive';
import { StyleFilterDirective } from 'src/app/shared/directives/style-filter.directive';
import { GoogleCategoryResultHeaderComponent } from '../google-category-result/google-category-result-header/google-category-result-header.component';
import { GoogleCategoryResultService } from 'src/app/shared/services/search/google-category-result.service';
import { BrandLogoFilterComponent } from 'src/app/shared/components/brand-logo-filter/brand-logo-filter.component';

@Component({
  selector: 'app-search-result',
  standalone: true,
  imports: [
    CommonModule,
    SearchSectionComponent,
    CheckboxModule,
    FormsModule,
    BottomSheetComponent,
    SortByComponent,
    MenuModule,
    RadioButtonModule,
    SliderModule,
    BreadcrumbComponent,
    EmptyStateDisplayComponent,
    TruncatePipe,
    ProgressBarModule,
    ShopModule,
    CommonComponentModule,
    FiltersComponent,
    GoogleFiltersComponent,
    StylePaginationDirective,
    StyleFilterDirective,
    GoogleCategoryResultHeaderComponent,
    DeviceTypeDirective,
    BrandLogoFilterComponent
],
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
})
export class SearchResultComponent implements OnInit, OnDestroy {
  @ViewChild('bSheet') bSheet !: BottomSheetComponent;
  @ViewChild('sortBy') sortBy !: SortByComponent;
  @ViewChild('filter') filter !: GoogleFiltersComponent;
  @ViewChild('googleResultHeader') googleResultHeader !: GoogleCategoryResultHeaderComponent;

  searchQuery = ''
  sortByitems: SortBy[] = SearchConst.CategoryItemDict
  results: any[] = []
  searchResponse!: SearchResponse
  totalItm = this.results.length
  currentPageNumber = 1
  totalPages!: number;
  pageSize: number = 20
  subsList !: Subscription[];
  priceRange: number[] = [];
  minPrice = 0;
  maxPrice !: number;
  showClear = false;
  filterChips: {
    label: string,
    filterName: string
  }[] = [];
  dom: any = {filter: null, header: null};

  featured_logo=''
  featured_label= ''
  featured_type=''
  isFeatured = false
  apiConfig ={baseApiUrl:environment.apiUri, tenantID:environment.whoAmI, merchantLogoUrl: environment.s3MerchantLogoUrl}
  turningFeaturedOff = false;
  sortValue = searchDefaultSort;
  showPagination = false
  skeletonLoader_isDataLoaded = false
  pageConstants = categoryResultPage
  isGoogleCategory = false

  constructor(
    private searchService: SearchService,
    public readonly swfService: SearchWithFilterService,
    private readonly cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private landingService: LandingService,
    private router: Router,
    public searchSecService: SearchSectionService,
    private ssfService: SearchSelectedFilterService,
    private googleCategoryResultService: GoogleCategoryResultService
  ) { }

  ngOnInit(): void {

    this.isGoogleCategory = this.searchSecService.isGoogleCategorization()

    this.subsList = [
      this.searchService.onSearch.subscribe({
        next: (query) => this.router.navigate(['search-result'],{
          queryParams: {
            ...this.route.snapshot.queryParams,
            query
          }
        })
      }),
      this.route.queryParams.subscribe({
        next: (qParams) => this.onParamsChange(qParams)
      }),
      this.swfService.onResult.subscribe({
        next: (res) =>{
          this.skeletonLoader_isDataLoaded = true
          this.setResult(res)
          scrollTo(0,0)
        }
      }),
      this.swfService.getrequestData$().subscribe(_ => this.showPagination = false),
      this.googleCategoryResultService.filterItems$.subscribe((data:any) => {
        const queryParams = this.route.snapshot.queryParams
        if(!queryParams['isFeatured']) return
        Object.keys(queryParams).forEach((key:string) => {
         const filter =  structuredClone(data.find((item:any) => item.label === key))
         if(!filter) return
         const featuredOption = filter?.options?.find((option:any) => option.label === queryParams[key])
         if(featuredOption && !featuredOption.isSelected) this.turnOffIsFeatured(filter.label)
        })
      })
    ]
  }

  onParamsChange(qParams: any) {
    const query = qParams['query']
    if(this.isGoogleCategory){
      this.ssfService.initializeFiltersFromQueryParams(qParams);
      this.cdRef.detectChanges();
    }
    if(this.turningFeaturedOff){
      this.turningFeaturedOff = false;
      return;
    }
    this.searchService.setQuery(query);
    this.searchQuery = query;
    this.filterChips = [];
    this.resetSortBy();
    this.swfService.resetSorting();
    this.priceRange = [];
    this.swfService.resetResponseData();
    this.isFeatured = this.route.snapshot.queryParams['isFeatured'] ===  "true";
    if(this.isFeatured){
      this.executeFeaturedMode()
    }else{
      this.executeNormalMode();
    }
    this.resetPageAndSearch();
    this.cdRef.detectChanges();
  }

  executeNormalMode(){
    this.showClear = false;
    this.swfService.resetRequestData();
  }

  executeFeaturedMode(){
    this.swfService.resetFilter();
    const featuredObj = this.initFeatured()
    this.swfService.featuredSearch(featuredObj)
  }

  ngOnDestroy(): void {
    this.subsList.forEach(sub => sub?.unsubscribe());
    this.searchService.setQuery('');
    this.swfService.resetResponseData();
  }

  initFeatured(){
    if(this.route.snapshot.queryParams['isFeatured'] == "true") this.isFeatured = true
    else return
    const brand = this.route.snapshot.queryParams[SearchConst.type.Brands]
    const merchant = this.route.snapshot.queryParams[SearchConst.type.Merchants]
    const categories = this.route.snapshot.queryParams[SearchConst.type.Categories];
    let result = {}

    if(brand){
      this.featured_type = SearchConst.type.Brands
      result = {...result,Brands:[brand]}
      this.landingService.getTopBrands().subscribe((data)=>this.updateFeaturedData(data,brand))
    }
    if(merchant){
      this.featured_type = SearchConst.type.Merchants
      result = {...result,Merchants:[merchant]}
      this.landingService.getTopMerchants().subscribe((data)=>this.updateFeaturedData(data,merchant))
    }
    if(categories){
      this.featured_type = SearchConst.type.Categories
      result = {...result,Categories:[categories]}
      const data = [{
        label: categories,
        image: null
      }];
      this.updateFeaturedData(data,categories)
    }
    return result
  }

  updateFeaturedData(data:any, name:string){
    const featured_ = data.find((obj:any) => obj.label === name)
    if(!featured_) this.router.navigateByUrl('/')
    this.featured_logo=featured_.image
    this.featured_label =featured_.label
    this.ssfService.addSelectedFiltersData(this.featured_label, this.featured_type, true)
  }

  updateSortBy(val: any) {
    this.swfService.requestData.sortBy = val;
    this.sortValue.value = val;
    this.resetPageAndSearch()
  }

  resetSortBy() {
    this.sortValue = {...searchDefaultSort}
    this.sortBy?.setSortBy(searchDefaultSort.label, searchDefaultSort.value);
    this.googleResultHeader?.sortBy?.setSortBy(searchDefaultSort.label, searchDefaultSort.value);
  }

  setSortValue(label: string){
    this.sortValue.label = label;
  }


  getDataByPage( pageNumber: number) {
    this.swfService.requestData.pageNumber = pageNumber
    this.currentPageNumber = pageNumber;
    this.swfService.search(this.searchQuery)
    this.showPagination = false
  }

  resetPageAndSearch(){
    this.getDataByPage(1);
  }

  updatePagination(pageNumber: number){
    this.getDataByPage(pageNumber)
  }

  openFilterSection() {
    this.bSheet?.open();
    this.filter?.openFilterSection();
  }

  setResult(response: SearchResponse) {
    this.currentPageNumber = response.currentPageNumber || 1;
    this.transform(response.data)
    this.totalItm = response.totalCount
    if(this.totalItm) {
      this.totalPages = Math.ceil(this.totalItm / this.pageSize);
      this.showPagination = true;
    }
    const { label, value } = this.sortValue;
    this.cdRef.detectChanges();
    this.sortBy?.setSortBy(label,value)
    this.googleResultHeader?.sortBy?.setSortBy(label,value)
    this.ssfService.setFilterList(structuredClone(response.aggregations.filters))
    this.cdRef.detectChanges();
  }


  transform(data: any) {
    this.results = data.map( (item: any) =>mapDataToSkuCard(item))
  }

  resultHeader(e:any) {
    if(!e?.type) return;
    if(e.type === 'updateSortBy') {
      this.updateSortBy(e.payload)
    }else if(e.type === 'openFilterSection') {
      this.openFilterSection()
    }else if(e.type === 'setSortByString') {
      this.setSortValue(e.payload)
    }
  }

  turnOffIsFeatured(featuredFilter:string){
    const queryParams = structuredClone(this.route.snapshot.queryParams)
    delete queryParams['isFeatured']
    delete queryParams[featuredFilter]
    if(this.isFeatured){
      this.isFeatured = false
      this.turningFeaturedOff = true;
      this.router.navigate(['search-result'],{
        queryParams: queryParams
      })
    }else{
      this.turningFeaturedOff = false;
    }
  }
}
