<div class="d-flex flex-row collection-breadcrumb l-container align-breadcrumb">
  <div class="breadcrumb-text" (click)="home()">Shop</div>

  <!-- single page -->
   <div class="align-breadcrumb" *ngIf="!isMultiLevel">
    <img src="../../../../assets/icons/right-arrow.svg" alt=""  class="right-arrow"/>
    <div class="breadcrumb-text last-child">{{page}}</div>
   </div>



  <!-- multi page - query or route based -->
  <div class="align-breadcrumb" *ngFor="let label of labels; let i = index" (click)="navigate(i)">
    <img src="../../../../assets/icons/right-arrow.svg" alt=""  class="right-arrow"/>
    <div class="breadcrumb-text" [ngClass]="{'last-child':i===labels.length-1}">{{label}}</div>
  </div>

</div>
