import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LaasyCacheService } from '@laasy/common-layout';
import { ShopService } from '@laasy/common-layout/shop';

@Component({
  selector: 'app-favourite-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './favourite-icon.component.html',
  styleUrls: ['./favourite-icon.component.scss']
})
export class FavouriteIconComponent {

@Input() item: any;

  constructor(private laasyCacheService: LaasyCacheService, private shopService: ShopService){}

  ngOnInit():void{
    this.shopService.savedSkuIDList$.asObservable().subscribe((skuIds :any)=>{
        if(skuIds.has(this.item.skuId)){
          this.item.isFavourite = true
        }
    })
  }

  handleFavouriteIconClick() {
    this.laasyCacheService.clearUserSavedSKU()
    if(!this.item.isFavourite) {
      this.shopService.addSavedProduct(this.item).subscribe(
        (res) => {
          if(res)
            this.item.isFavourite = true;
        }
      );
    } else {
      this.shopService.deleteSavedProduct(this.item).subscribe(
        (res) => {
          if(res) {
            this.item.isFavourite = false;
            const deletedData = {
              isDeleted : res,
              skuId: this.item.skuId
            }
          } else {
            this.item.isFavourite = true;
          }

        }
      );
    }
    return this.item;
  }
}
