<app-search-section></app-search-section>
<app-breadcrumb [page]="page"></app-breadcrumb>
<div class="page-info">
  <div class="collection-result-banner">
    <app-static-banner-card *appDeviceType="'mobile'" [bgColor]="banner.color" appStaticBannerCard>
      <div headingSection>
        <div>{{banner.heading1}}</div>
        <div>{{banner.heading2}}</div>
      </div>
      <div rightSection class="collection-result-img-container">
        <img [src]="banner.img" width="100%" height="100%" style="object-fit: contain;object-position: right;" libBrokenImageHider>
      </div>
    </app-static-banner-card>

    <div *appDeviceType="'desktop'" class="collection-result-banner-desktop" [ngStyle]="{'background':banner.color}">
      <div class="collection-banner-desktop-img bg-left" [ngStyle]="getStyle(banner.imgLeft,'left')"></div>
      <div class="collection-banner-desktop-text">
        <div>{{banner.heading1}}</div>
        <div>{{banner.heading2}}</div>
      </div>
      <div class="collection-banner-desktop-img bg-right" [ngStyle]="getStyle(banner.img)"></div>
    </div>
  </div>

  <div class="collection-result-header">
    <div class="header-title" *appDeviceType="'desktop'">{{resultHeader}}</div>
    <app-sort-by #sortBy [items]="sortByitems" (selectedString)="updateSortBy($event)"></app-sort-by>
  </div>
    <app-shop-product-card [isCentered]="true" [items]="results" [isFlexwrap]="true" [isDataLoaded]="skeletonLoader_isDataLoaded"></app-shop-product-card>
</div>
