import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteTrackerService implements OnDestroy{
  private previousUrl: string | null = null;
  private currentUrl: string | null = null;
  private unsubscribe$ = new Subject<void>();


  constructor(private router: Router) {
    this.currentUrl = this.router.url;

    this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.unsubscribe$),
    )
    .subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = sessionStorage.getItem('currentUrl') || null;
        this.currentUrl = event.urlAfterRedirects;

        sessionStorage.setItem('previousUrl', this.previousUrl || '');
        sessionStorage.setItem('currentUrl', this.currentUrl);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
