import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchSectionComponent } from 'src/app/shared/components/search-section/search-section.component';
import { DeviceTypeDirective, mapDataToSkuCard } from '@laasy/common-layout';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { SearchService } from 'src/app/shared/services/search/search.service';
import { categoryResultPage, SearchConst, searchDefaultSort } from 'src/app/shared/utilities/constants';
import { SearchResponse } from 'src/app/shared/models/Search';
import { BottomSheetComponent } from 'src/app/shared/components/bottom-sheet/bottom-sheet.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MenuModule } from 'primeng/menu';
import { SearchWithFilterService } from 'src/app/shared/services/search/search-with-filter.service';
import { Subscription } from 'rxjs';
import { SliderModule } from 'primeng/slider';
import { ActivatedRoute, Router } from '@angular/router';
import { LandingService } from 'src/app/shared/services/Page/landing.service';
import { BreadcrumbComponent } from 'src/app/shared/components/breadcrumb/breadcrumb.component';
import { SortBy } from 'src/app/shared/models/sortBy';
import { environment } from 'src/environments/environment';
import { SearchSectionService } from 'src/app/shared/services/search/search-section.service';
import { EmptyStateDisplayComponent } from 'src/app/shared/components/empty-state-display/empty-state-display.component';
import { TruncatePipe } from 'src/app/shared/pipes/truncate.pipe';
import { ProgressBarModule } from 'primeng/progressbar';
import { ShopModule } from '@laasy/common-layout/shop';
import { CommonComponentModule } from '@laasy/common-layout/common-components';
import { FiltersComponent } from "../../shared/components/filters/filters.component";
import { SearchSelectedFilterService } from 'src/app/shared/services/search/search-selected-filter.service';
import { multiLevelBreadCrumb } from 'src/app/shared/components/breadcrumb/model';
import { GoogleFiltersComponent } from 'src/app/shared/components/google-filters/google-filters.component';
import { GoogleCategoryResultService } from 'src/app/shared/services/search/google-category-result.service';
import { StylePaginationDirective } from 'src/app/shared/directives/style-pagination.directive';
import { StyleFilterDirective } from 'src/app/shared/directives/style-filter.directive';
import { FilterChipsComponent } from 'src/app/shared/components/google-filters/filter-chips/filter-chips.component';
import { filterActionList } from 'src/app/shared/components/google-filters/constant';
import { GoogleSortByComponent } from 'src/app/shared/components/google-sort-by/sort-by.component';
import { GoogleCategoryResultHeaderComponent } from './google-category-result-header/google-category-result-header.component';
import { BrandLogoFilterComponent } from 'src/app/shared/components/brand-logo-filter/brand-logo-filter.component';

@Component({
  selector: 'app-google-category-result',
  standalone: true,
  imports: [
    CommonModule,
    SearchSectionComponent,
    CheckboxModule,
    FormsModule,
    BottomSheetComponent,
    GoogleSortByComponent,
    MenuModule,
    RadioButtonModule,
    SliderModule,
    BreadcrumbComponent,
    EmptyStateDisplayComponent,
    TruncatePipe,
    ProgressBarModule,
    ShopModule,
    CommonComponentModule,
    FiltersComponent,
    GoogleFiltersComponent,
    StylePaginationDirective,
    StyleFilterDirective,
    FilterChipsComponent,
    DeviceTypeDirective,
    GoogleCategoryResultHeaderComponent,
    BrandLogoFilterComponent
  ],
  templateUrl: './google-category-result.component.html',
  styleUrls: ['./google-category-result.component.scss'],
})
export class GoogleCategoryResultComponent implements OnInit, OnDestroy {
  @ViewChild('sortBy') sortBy !: GoogleSortByComponent;
  @ViewChild('googleResultHeader') googleResultHeader !: GoogleCategoryResultHeaderComponent;
  @ViewChild('filter') filter !: GoogleFiltersComponent;
  @ViewChild(BreadcrumbComponent) bcComponent!: BreadcrumbComponent;


  searchQuery = ''
  sortByitems: SortBy[] = SearchConst.CategoryItemDict
  results: any[] = []
  searchResponse!: SearchResponse
  totalItm = this.results.length
  currentPageNumber = 1
  totalPages!: number;
  pageSize: number = 20
  subsList !: Subscription[];
  dom: any = { filter: null, header: null };

  sortValue = searchDefaultSort;
  showPagination = false
  skeletonLoader_isDataLoaded = false
  pageConstants = categoryResultPage
  breadcrumbData: multiLevelBreadCrumb[] = []
  activeCategory: string = '';
  isBreadCrumbUpdated = false;

  constructor(
    private searchService: SearchService,
    public readonly swfService: SearchWithFilterService,
    private readonly cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    public searchSecService: SearchSectionService,
    private ssfService: SearchSelectedFilterService,
    private googleCategoryResultService: GoogleCategoryResultService
  ) { }

  ngOnInit(): void {
    this.subsList = [
      this.searchService.onSearch.subscribe({
        next: (query) => this.router.navigate([this.pageConstants.route], {
          queryParams: {
            ...this.route.snapshot.queryParams,
            query
          }
        })
      }),
      this.route.queryParams.subscribe({
        next: (qParams) => {
          this.onParamsChange(qParams)
          this.breadcrumbData = this.googleCategoryResultService.getBreadcrumb(qParams)
          this.cdRef.detectChanges();
        }
      }),
      this.swfService.onResult.subscribe({
        next: (res) => {
          this.skeletonLoader_isDataLoaded = true
          this.setResult(res)
          scrollTo(0, 0)
        }
      }),
      this.swfService.getrequestData$().subscribe(_ => this.showPagination = false)
    ]
  }

  ngAfterViewChecked(): void {
    const oldFunction = this.bcComponent.navigate.bind(this.bcComponent);
    if (oldFunction && !this.isBreadCrumbUpdated) {
      this.isBreadCrumbUpdated = true;
      this.bcComponent!.navigate = (i: number) => {
        this.swfService.resetRequestData();
        oldFunction(i);
      };
    }
  }


  onParamsChange(qParams: any) {
    const query = qParams['query']
    this.activeCategory = this.ssfService.initializeFiltersFromQueryParams(qParams);
    this.cdRef.detectChanges();
    this.searchService.setQuery(query);
    this.searchQuery = query;
    this.resetSortBy();
    this.swfService.resetSorting();
    this.swfService.resetResponseData();
    this.resetPageAndSearch();
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subsList.forEach(sub => sub?.unsubscribe());
    this.searchService.setQuery('');
    this.swfService.resetResponseData();
  }

  updateSortBy(val: any) {
    this.swfService.requestData.sortBy = val;
    this.sortValue.value = val;
    this.cdRef.detectChanges();
    this.resetPageAndSearch()
  }

  resetSortBy() {
    this.sortValue = { ...searchDefaultSort }
    this.sortBy?.setSortBy(searchDefaultSort.label, searchDefaultSort.value);
    this.googleResultHeader?.sortBy?.setSortBy(searchDefaultSort.label, searchDefaultSort.value);
  }

  setSortValue(label: string) {
    this.sortValue.label = label;
    this.cdRef.detectChanges();
  }

  getDataByPage(pageNumber: number) {
    this.swfService.requestData.pageNumber = pageNumber
    this.currentPageNumber = pageNumber;
    this.swfService.search(this.searchQuery)
  }

  resetPageAndSearch() {
    this.getDataByPage(1);
  }

  openFilterSection() {
    this.filter.openFilterSection()
  }

  closeFilterSection() {
    this.filter.closeFilterSection()
  }

  setResult(response: SearchResponse) {
    this.currentPageNumber = response.currentPageNumber || 1;
    this.transform(response.data)
    this.totalItm = response.totalCount
    if(this.totalItm){
      this.totalPages = Math.ceil(this.totalItm / this.pageSize);
      this.showPagination = true
    }
    this.cdRef.detectChanges();
    const { label, value } = this.sortValue;
    this.sortBy?.setSortBy(label, value);
    this.googleResultHeader?.sortBy?.setSortBy(label, value);
    this.ssfService.setFilterList(structuredClone(response.aggregations.filters))
    this.cdRef.detectChanges();
  }


  transform(data: any) {
    this.results = data.map((item: any) => mapDataToSkuCard(item))
  }

  resultHeader(e:any) {
    if(!e?.type) return;
    if(e.type === 'updateSortBy') {
      this.updateSortBy(e.payload)
    }else if(e.type === 'openFilterSection') {
      this.openFilterSection()
    }else if(e.type === 'setSortByString') {
      this.setSortValue(e.payload)
    }
  }

}
