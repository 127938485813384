import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonLayoutModule } from '@laasy/common-layout';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { ToastModule } from "primeng/toast";
import { MessageService } from 'primeng/api';
import { AuthGuard } from './core/guards/auth.guard';
import { HttpExchangeInterceptor } from './core/interceptors/http.Interceptor';
import { TenantContextResolver } from './core/resolvers/tenant-context.resolver';
import { NotificationService } from './shared/services/common/notification.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { dynamicRoutesFactory, ROUTES } from "./app-routing.module";
import { SearchService } from './shared/services/search/search.service';
import { TenantService } from './shared/services/tenant/tenant.service';
import { environment } from 'src/environments/environment';
import { RouteTrackerService } from './shared/services/Page/route-tracker.service';

export function fetchDataFactory(apiService: TenantService) {
  return ()=>apiService.getTenantAppConfigPromise()
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonModule,
    CommonLayoutModule.forRoot(environment),
    LayoutComponent,
    ToastModule,
    RouterModule.forRoot(ROUTES)
  ],
  providers: [
    TenantContextResolver,
    MessageService,
    NotificationService,
    HttpClient,
    RouteTrackerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpExchangeInterceptor,
      multi: true
    },
    AuthGuard,
    SearchService,
    { provide: APP_INITIALIZER, useFactory: fetchDataFactory, deps: [TenantService], multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (TenantService: TenantService, router: Router) => () => dynamicRoutesFactory(TenantService).then(routes => {
        const currentRoutes = router.config;
        const finalRoutes = [...routes,...currentRoutes];
        router.resetConfig(finalRoutes);
      }),
      deps: [TenantService, Router],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
