<div class="favouriteIconContainer favouriteIconOutline" (click)="handleFavouriteIconClick()">
  <svg  *ngIf="item.isFavourite; else notFavourite" width="20" height="18" viewBox="0 0 20 18"
    fill="none" xmlns="http://www.w3.org/2000/svg" style="float:right; cursor: pointer;">
  <path
      d="M1.55117 1.95828C3.62372 -0.102628 6.97628 -0.102628 9.04883 1.95828L9.05213 1.96156L9.9918 2.90763L10.9397 1.95977L10.9412 1.95828C13.013 -0.101895 16.3539 -0.102628 18.4266 1.95608C19.4879 2.9952 20 4.35143 20 5.72009C20 7.06867 19.4881 8.43262 18.4503 9.47042L10.598 17.3227L10.598 17.3227C10.56 17.3607 10.5079 17.4129 10.416 17.4741C10.1641 17.6421 9.8359 17.6421 9.58397 17.4741C9.49214 17.4129 9.43999 17.3608 9.40197 17.3227L9.40195 17.3227L1.54967 9.47042C0.512465 8.43322 0 7.07954 0 5.72009C0 4.36239 0.511284 2.99816 1.54967 1.95977L1.55117 1.95828Z"
      fill="var(--l-secondary-tenant-color)" />
  </svg>
  <ng-template #notFavourite>
    <svg  xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M3.84262 6.01757C3.22503 6.6355 2.91797 7.44836 2.91797 8.26666C2.91797 9.08379 3.22425 9.88906 3.84325 10.5081L10.0013 16.6661L16.1594 10.5081C16.7779 9.88956 17.0846 9.07619 17.0846 8.26666C17.0846 7.44134 16.7787 6.63899 16.1476 6.02182L16.1439 6.0182C14.9046 4.78581 12.9072 4.7856 11.6676 6.01761C11.6674 6.0178 11.6678 6.01742 11.6676 6.01761L10.4349 7.25027C10.3174 7.36774 10.158 7.43361 9.99191 7.43333C9.82578 7.43305 9.6666 7.36664 9.54953 7.24877L8.32727 6.01818C8.32683 6.01775 8.32639 6.01731 8.32595 6.01687C7.08651 4.78581 5.08177 4.78604 3.84262 6.01757ZM2.96061 5.13182C4.68773 3.41439 7.48154 3.41439 9.20866 5.13182L9.21141 5.13455L9.99447 5.92295L10.7844 5.13306L10.7856 5.13182C12.5121 3.41501 15.2962 3.4144 17.0235 5.12998C17.9079 5.99592 18.3346 7.12611 18.3346 8.26666C18.3346 9.39047 17.9081 10.5271 17.0432 11.3919L10.4997 17.9355C10.468 17.9672 10.4245 18.0107 10.348 18.0617C10.1381 18.2017 9.86455 18.2017 9.65461 18.0617C9.57807 18.0107 9.53461 17.9672 9.50293 17.9355L2.95936 11.3919C2.09502 10.5276 1.66797 9.39954 1.66797 8.26666C1.66797 7.13525 2.09404 5.99838 2.95936 5.13306L2.96061 5.13182Z"
            [attr.fill]="item.isFavourite ? 'var(--l-primary-tenant-color)' : '#888888'" />
    </svg>
  </ng-template>
</div>
