import { Component, OnDestroy, OnInit, HostListener, ViewChild, AfterViewChecked, ElementRef, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { filter, first } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { TenantService } from './shared/services/tenant/tenant.service';
import { Location } from '@angular/common';
import { Title } from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import {
  getHostName,
  AnalyticsService,
  ScriptLoaderService,
  CommonService
} from '@laasy/common-layout';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from './shared/services/authentication/authentication.service';
import { ShopService } from '@laasy/common-layout/shop';
import { SearchWithFilterService } from './shared/services/search/search-with-filter.service';
import { RouteTrackerService } from './shared/services/Page/route-tracker.service';

declare const analytics: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewChecked {
  title = 'LaaSy';
  layoutComponent: string = 'app-layout';
  favIcon: any
  @ViewChild('toast', { read: ElementRef, static: false }) toastElementRef: ElementRef | undefined;
  private observerInitialized = false;
  hostName: string = '';
  prevUrl: string = '';

  constructor(private commonService:CommonService,private scriptService:ScriptLoaderService, private analyticsService: AnalyticsService, private readonly authenticationService: AuthenticationService, private cookieService: CookieService, private _router: Router, private location: Location, private tenantService: TenantService, private titleService: Title,private swfService:SearchWithFilterService, private routeTracker: RouteTrackerService) {}


  async ngOnInit() {
    this.tenant_config();
    this.commonService.getUserContext().pipe(first()).subscribe()
    this.commonService.getUserCashback().pipe(first()).subscribe()
    setInterval(() => this.refreshToken(), environment.refreshTokenInterval * 60 * 1000)
    this._router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: any) => {

        if (!['search-result', 'category-result'].some(route => event.url.includes(route))) {
          this.swfService.showPriceChip = false;
        }
        this.prevUrl = event.url;
    });
  }
  async refreshToken() {
    const currentSession = await this.authenticationService.GetCurrentSession()
    if (currentSession !== null) {
      const idTokenExpire = currentSession.getIdToken().getExpiration()
      sessionStorage.setItem("token_expire_datetime", idTokenExpire)
    }
  }

  tenant_config() {
    this.favIcon = document.querySelector('#appIcon') || null;
    this.hostName = getHostName(this.location)
    this.tenantService.getTenantAppConfig().subscribe((res: any) => { })
  }

  ngAfterViewChecked(): void {
    const analytics = this.analyticsService.getSegmentAnalytics();
    if (analytics)
      this.observerInitialized = this.analyticsService.analytics_capture_toast(
        analytics,
        this.observerInitialized,
        this.toastElementRef
      );
  }


}


