<app-search-section></app-search-section>
<div class="container-laasy"*ngFor="let item of layout">
    <ng-container [ngTemplateOutlet]="heroBanner" *ngIf="item.name === components.heroBanner"></ng-container>
    <ng-container [ngTemplateOutlet]="topBrands" *ngIf="item.name === components.topBrands"></ng-container>
    <ng-container [ngTemplateOutlet]="shopByCategory" *ngIf="item.name === components.shopByCategory"></ng-container>
    <ng-container [ngTemplateOutlet]="collection" *ngIf="item.name.includes(components.collectionCarousel)" [ngTemplateOutletContext]="{ skus: item.data?.skus, title:item.data?.bannerTitle, collectionName:item.data?.collectionName, isDataLoaded:item.dataLoaded }"></ng-container>
    <ng-container [ngTemplateOutlet]="collectionCard" *ngIf="item.name.includes(components.collectionCard)" [ngTemplateOutletContext]="{data:item.data}"></ng-container>
    <ng-container [ngTemplateOutlet]="communityStats" *ngIf="item.name === components.communityStats"></ng-container>
</div>

<ng-template #shopByCategory>
  <app-circular-logo-label
    [title]="'Shop by category'"
    appShopByCategory
    [data]="categoryData$"
    [noImageBorder]="true"
    (cardClicked)="searchSecService.categoryClick($event)">
  </app-circular-logo-label>
</ng-template>

<ng-template #topBrands>
  <app-circular-logo-label
    [title]="'Discover cashback on your favorite brands'"
    [data]="topBrands$" [type]="'Brands'"
    (cardClicked)="FeaturedSearchBy($event)">
  </app-circular-logo-label>
</ng-template>


<ng-template #heroBanner>
  <app-banner-card [bannerDetails]="bannerData" [ellipseClass]="'ellipse-shop-hero-banner'" *ngIf="getBannerComponent().dataLoaded" appBannerImageFix></app-banner-card>
  <ng-container *ngIf="!getBannerComponent().dataLoaded">
    <p-skeleton width="auto" height="12.5rem" class="br-16" *appDeviceType="'desktop'"/>
    <p-skeleton width="auto" height="7.25rem" class="br-16" *appDeviceType="'mobile'"/>
  </ng-container>
</ng-template>

<ng-template #topRetailers>
  <app-circular-logo-label [title]="'Earn cashback at top retailers'" [data]="topRetailers$" [type]="'Merchants'" (cardClicked)="FeaturedSearchBy($event)"></app-circular-logo-label>
</ng-template>

<ng-template #collection let-skus="skus" let-title="title" let-collectionName="collectionName" let-isDataLoaded="isDataLoaded">
  <app-shop-collection
      [title]="title"
      [collectionName]="collectionName"
      [featuredData]="skus | mapCollectionSectionSkus"
      [isDataLoaded]="isDataLoaded">
    </app-shop-collection>
</ng-template>

<ng-template #collectionCard let-data="data">
  <app-collections [data]="data | mapCardCollection"></app-collections>
</ng-template>

<ng-template #communityStats>
  <lib-community-stats class="m-2"></lib-community-stats>
</ng-template>


