import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CashbackChipComponent, ShopModule, ShopService } from '@laasy/common-layout/shop';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { SearchSectionComponent } from "../search-section/search-section.component";
import { isDropshipSku } from '../../utilities/helpers';
import { DeviceTypeDirective, getCachedTenantData, getFormattedString, getUserID, PricePipe } from '@laasy/common-layout';
import { ButtonModule } from 'primeng/button';
import { categoryResultPage, searchResultPage } from '../../utilities/constants';
import { multiLevelBreadCrumb } from '../breadcrumb/model';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from '../../services/search/search.service';
import { TooltipModule } from 'primeng/tooltip';
import { FavouriteIconComponent } from '../favourite-icon/favourite-icon.component';
import { interval, retry, Subscription, take } from 'rxjs';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { ProductDetailsService } from '../../services/Page/product-details.service';

@Component({
  selector: 'app-product-details-page',
  standalone: true,
  imports: [CommonModule, BreadcrumbComponent, SearchSectionComponent, PricePipe, CashbackChipComponent, ButtonModule, ShopModule, DeviceTypeDirective, TooltipModule, FavouriteIconComponent, TruncatePipe],
  templateUrl: './product-details-page.component.html',
  styleUrls: ['./product-details-page.component.scss'],
  providers: [TruncatePipe]
})
export class ProductDetailsPageComponent implements OnInit, AfterViewChecked, OnDestroy {

  productDetails: any;
  breadcrumbData: multiLevelBreadCrumb[] = [];
  isDropshipSku: boolean = false;
  isActive: boolean = true;
  isProdDetailsExpanded: boolean = false;
  pageConstants = categoryResultPage;
  tooltipText = '';
  isDataLoaded: boolean = false;
  isProdDetailsOverflowing: boolean = false;
  @ViewChild('prodDetails', {static: false}) prodDetails!: ElementRef
  subs:Subscription[] = []
  maxHeight = 60;
  hasGoogleCategorization = false;
  additionalQueryParams = {};

  constructor(
    private shopService: ShopService,
    private productService: ProductDetailsService,
    private searchService: SearchService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject('environment') private env: any,
    private cdRef: ChangeDetectorRef,
    private truncatePipe: TruncatePipe,
  ) { }

  ngOnInit(): void {
    this.subs = [
      this.productService.getProductDetails(this.route.snapshot.queryParams['skuId']).pipe(retry(1)).subscribe({
        next: (data: any) => {
          this.productDetails = data;
          this.breadcrumbData = this.createBreadcrumbData();
          this.isDropshipSku = isDropshipSku(data?.sourceName);
          this.getAffiliateLink(this.productDetails);
          this.isDataLoaded = true;
          if(data?.isActive === 0 || data?.isActive === false) this.isActive = false;
        },
        error: (error: any) => {
          console.log(error);
        }
      }),
      this.searchService.onSearch.subscribe({
        next: (query) => this.router.navigate(['search-result'], {
          queryParams: {
            ...this.route.snapshot.queryParams,
            query
          }
        })
      })
    ]
    const tenantData = getCachedTenantData();
    this.tooltipText = 'Purchase directly on '+ tenantData?.name;
    this.hasGoogleCategorization = tenantData?.hasGoogleCategorization;
  }

  ngAfterViewChecked() {
    if (this.prodDetails) {
      this.checkOverflow();
    }
    this.cdRef.detectChanges();
  }

  getAffiliateLink(item: any) {
    if (this.productDetails.isAPITriggered == undefined){
      this.productDetails.isAPITriggered = false
      this.productDetails.isLoading = true
    }
    this.productDetails.merchantLogoUrl = this.env?.s3MerchantLogoUrl + getFormattedString(item?.websiteName) + 'Logo.png';
    if (!this.productDetails.isAPITriggered) {
      this.productDetails.isAPITriggered = true
      if(this.isDropshipSku){
        // const userID = getUserID();
        const productUrl =  this.productDetails.productURL //this.appendRedirectInfo(this.productDetails.productURL,{key:'userID',val:userID})
        this.productDetails.isAPITriggered = false
        this.productDetails.affiliateLink = productUrl
        this.productDetails.isLoading = false
        return;
      }

      this.subs.push(this.shopService.getAffiliateLink(this.productDetails.productURL, this.productDetails.SourceCampaignId).subscribe((data: any) => {
        this.productDetails.affiliateLink = data
        this.productDetails.isAPITriggered = false
        this.productDetails.isLoading = false
      }, (error) => {
        this.productDetails.isAPITriggered = false
        this.productDetails.isLoading = true
        console.log(error)
      }))
    }
  }

  appendRedirectInfo(link: string,paramMap:{key:string; val:string|null}){
    const redirectURL = new URL(link)
    if(paramMap.val)
      redirectURL.searchParams.append(paramMap.key, paramMap.val)
    return redirectURL.toString()
  }

  checkOverflow() {
    const el = this.prodDetails?.nativeElement;
    this.isProdDetailsOverflowing = el?.scrollHeight > this.maxHeight;
  }

  activateOffer(affiliateLink: any): void {
    if(affiliateLink) {
      this.shopService.logPurchaseLinkClick({ skuId: this.productDetails?.skuId, purchaseAffiliateUrl: affiliateLink }).subscribe((data) => {
        this.productDetails.isLoading = false;
        console.log('affiliate link clicked');
      });
      this.productService.activateOffer(this.productDetails, affiliateLink, this.isDropshipSku);
    }
  }

  toggleText(): void {
    this.isProdDetailsExpanded = !this.isProdDetailsExpanded;
  }

  createBreadcrumbData(): any {
    let breadcrumbArray = [];
    if(this.hasGoogleCategorization) {
      if(this.productDetails.googleCategoryLevel1) {
        breadcrumbArray.push ({
            label: this.productDetails.googleCategoryLevel1,
            route: this.productDetails.googleCategoryLevel1,
            queryName: 'Categories'
        });
      }
      if(this.productDetails.googleCategoryLevel2) {
        breadcrumbArray.push ({
            label: this.productDetails.googleCategoryLevel2,
            route: this.productDetails.googleCategoryLevel2,
            queryName: 'CategoriesLevel2'
        });
      }
      if(this.productDetails.googleCategoryLevel3) {
        breadcrumbArray.push ({
            label: this.productDetails.googleCategoryLevel3,
            route: this.productDetails.googleCategoryLevel3,
            queryName: 'CategoriesLevel3'
        });
      }
    } else {
      if(this.productDetails.categoryLevel1) {
        this.pageConstants = searchResultPage;
        this.additionalQueryParams = { query:'', isFeatured: true };
        breadcrumbArray.push ({
            label: this.productDetails.categoryLevel1,
            route: this.productDetails.categoryLevel1,
            queryName: 'Categories'
        });
      }
    }

    breadcrumbArray.push ({
      label: this.getTruncatedText(this.productDetails.productName, 40),
      route: this.productDetails.productName,
      queryName: 'CategoriesLevel4'
    });
    return breadcrumbArray;
  }

  getTruncatedText(text: string, maxLength: number): string {
    return this.truncatePipe.transform(text, maxLength);
  }

  goBack(): void {
    const previousRoute = sessionStorage.getItem('previousUrl') || '';
    const currentRoute = sessionStorage.getItem('currentUrl') || '';
    if(previousRoute === currentRoute || previousRoute === '')
      this.router.navigateByUrl('/')
    else
      window.history.back();
  }

  ngOnDestroy(): void{
    this.subs.forEach(sub => sub.unsubscribe())
  }

}
