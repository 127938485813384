import { Component, HostListener, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { landingComponentModule } from '@laasy/common-layout/home';
import { BrokenImageHiderDirective } from '@laasy/common-layout';

@Component({
  selector: 'app-collections',
  standalone: true,
  imports: [CommonModule, landingComponentModule,BrokenImageHiderDirective],
  templateUrl: './collections-section.component.html',
  styleUrls: ['./collections-section.component.scss']
})
export class CollectionsComponent {
  @Input() data: any = []
  bannerIndex = 0
  isMobile = false

  constructor(private router: Router) { this.onWindowResize() }


  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onWindowResize(): void {
    this.isMobile = window.innerWidth < 960;
  }

  updateBannerIndex(i: any) {
    this.bannerIndex = i
  }

  navigate(link: string) {
    this.router.navigateByUrl(link)
  }

  getImage(x: any) {
    return { 'width': '100%', 'height': '100%', 'background-image': "url('" + x + "')" }
  }

  getStyle(data: any) {
    return { background: "url('" + data[this.bannerIndex].img + "')", 'background-size': 'contain', 'background-position': '100% 50%', 'background-repeat': 'no-repeat' }
  }

}
