import { Injectable } from '@angular/core';
import { AnalyticsService, AuthorizedApiService, getUserID, productDetailsApi } from '@laasy/common-layout';
import { Observable, interval, map, take, tap } from 'rxjs';
import { SegmentEvent, skuDataToProductDetailsPage } from '../../utilities/constants';
import { ShopService } from '@laasy/common-layout/shop';

@Injectable({
  providedIn: 'root'
})
export class ProductDetailsService {

  constructor(private authApiService: AuthorizedApiService, private shopService: ShopService, private analyticsService: AnalyticsService) { }

  getProductDetails(skuId: string): Observable<any> {
    return this.authApiService.get(productDetailsApi + skuId).pipe(
      map((res: any) => {
        return skuDataToProductDetailsPage(res);
      }),
      tap((productDetails: any) => this.sendSegmentEvent(productDetails,SegmentEvent.viewPDP))
    )
  }

  private sendSegmentEvent(productDetails: any,eventName:string) {
    const eventData = Object.fromEntries(
      Object.entries({
        name: productDetails.productName,
        category: productDetails.categoryLevel1,
        googleCategoryLevel1: productDetails.googleCategoryLevel1,
        googleCategoryLevel2: productDetails.googleCategoryLevel2,
        googleCategoryLevel3: productDetails.googleCategoryLevel3,
        googleCategoryLevel4: productDetails.googleCategoryLevel4,
        googleCategoryLevel5: productDetails.googleCategoryLevel5,
        googleCategoryLevel6: productDetails.googleCategoryLevel6,
        googleCategoryLevel7: productDetails.googleCategoryLevel7,
        retailer: productDetails.sourceName,
        price: productDetails.price,
        cashbackAmount: productDetails.cashback,
        skuId: productDetails.skuId,
        affliateLink: productDetails.affiliateLink,
      }).filter(([_, value]) => value != null)
    );

    this.analyticsService.segmentTrackEvent(eventData, eventName)
  }

  activateOffer(productDetails: any, affiliateLink: any,isDropshipSku:boolean): void {
    this.sendSegmentEvent({...productDetails, affiliateLink:affiliateLink}, SegmentEvent.activateOffer);
    if (!isDropshipSku) {
      window.open(affiliateLink, '_blank');
      return;
    }

    const userID = getUserID();
    const message = {
      data: 'laasy.co',
      values: {
        redirect: window.location.origin + '/',
        userID: userID
      }
    };
    try {
      const urlDomain = new URL(affiliateLink).hostname;
      const domain = urlDomain.split('.').slice(-2).join('.');
      console.log(domain);
      this.setCookiecross('l-redirect', window.location.origin + '/', `.${domain}`);
    } catch (error) {
      console.log('Error : setting cookie');
    }


    const targetWindow = window.open(affiliateLink, '_blank');
    interval(500).pipe(take(20)).subscribe(() => {
      targetWindow?.postMessage(message, affiliateLink);
    });

  }

  private setCookiecross(name: string, value: string, domain: string) {
    document.cookie = `${name}=${encodeURIComponent(value)}; domain=${domain}; path=/; SameSite=None; Secure`;
  }
}
