import { AfterViewInit, ContentChild, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Subscription, fromEvent, debounceTime } from 'rxjs';

@Directive({
  selector: '[appBannerImageFix]',
  standalone: true
})
export class BannerImageFixDirective implements OnInit, AfterViewInit, OnDestroy {

  resizeSubscription!: Subscription;
  el: ElementRef;
  @Input() ellipseClass!: string;

  constructor(private renderer: Renderer2, el: ElementRef) {
    this.el = el;
  }

  positionRightContainer(): void {
    const rightContainer = this.el.nativeElement.querySelector('.right-container');

    if (rightContainer) {
      let rpos = '0px';
      if (window.innerWidth > 768) {
        switch (true) {
          case (window.innerWidth >= 1550 && window.innerWidth < 1800):
            rpos = '70px';
            break;
          case (window.innerWidth >= 1800 && window.innerWidth < 2100):
            rpos = '100px';
            break;
          case (window.innerWidth >= 2100 && window.innerWidth < 2500):
            rpos = '170px';
            break;
          case (window.innerWidth >= 2500):
            rpos = rightContainer.clientWidth/2 + 'px';
            break;
          default:
            rpos = '0px';
        }
      }
      this.renderer.setStyle(rightContainer, 'right', rpos);
    }

  }


  fix() {
    this.positionRightContainer();
    this.positionImage()
  }

  positionImage(): void {
    const img = this.el.nativeElement.querySelector('#dynamic-banner-img');
    const heading = this.el.nativeElement.querySelector('.left-container');
    const parentImage = img?.parentElement?.parentElement;
    if (parentImage && heading) {
      this.renderer.setStyle(parentImage, 'display', 'flex');
      this.renderer.setStyle(parentImage, 'flex-direction', 'row-reverse');
      this.renderer.setStyle(img, 'transform', 'translate(-100%)');
      this.renderer.setStyle(heading, 'align-items', 'center');
    }
  }

  ngOnInit(): void {
    this.setupResizeListener();
  }

  ngAfterViewInit(): void {
    this.fix();
  }

  ngOnDestroy(): void {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }

  private setupResizeListener(): void {
    this.resizeSubscription = fromEvent(window, 'resize')
      .pipe(debounceTime(200))
      .subscribe(() => this.fix());
  }
}
