import { Injectable } from '@angular/core';
import { ShopService } from '@laasy/common-layout/shop';
import { first, map, Observable, of } from 'rxjs';
import { BannerDetails, collectionType } from '../../utilities/constants';
import { AuthorizedApiService, CommonService, sortItems } from '@laasy/common-layout';
import { splitArrayIntoChunks } from '../../utilities/helpers';
import { Banner } from '@laasy/common-layout/home/model';

@Injectable({
  providedIn: 'root'
})
export class LandingService {

  constructor(private shopApiService:ShopService,private commonService:CommonService,private authApiService:AuthorizedApiService) { }

  getTopMerchants(): Observable<any> {
    return this.shopApiService.getTopMerchants()
  }

  getTopBrands(): Observable<any> {
    return this.shopApiService.getTopBrands()
  }

  getCollections(collectionTyp: string): Observable<any[]> {
    const systemAndNonSystem$ = this.shopApiService.getCollections()
    const userPref$ = this.shopApiService.getUserPrefCollections().pipe(map((resp: any) => resp || []))
    return (collectionType.user === collectionTyp) ? userPref$ : systemAndNonSystem$
      .pipe(
        map((resp: any[]) => {
          const collectionCards = resp.reduce((acc: any, item: any) => {
            if (item.isCard) {
              acc.cards.push(item);
              acc.levels.add(item.level);
            } else {
              acc.nonCards.push(item);
            }
            return acc;
          }, { cards: [], nonCards: [], levels: new Set() });


        const collectionCardsSplitByLevel = Array.from(collectionCards.levels).flatMap(level => {
        const cardsAtLevel = collectionCards.cards.filter((card: any) => card.level === level);
        const sortedCards = sortItems(cardsAtLevel, 'rank', false);
        const continuousCards = sortedCards.reduce((acc: any, card: any) => {
          const lastGroup = acc[acc.length - 1];
          if (lastGroup && (lastGroup[lastGroup.length - 1].rank + 1 === card.rank || lastGroup[lastGroup.length - 1].rank === card.rank)) {
          lastGroup.push(card);
          } else {
          acc.push([card]);
          }
          return acc;
        }, []);

        // grouping cards into chunks of 3
        return continuousCards.flatMap((group: any) => splitArrayIntoChunks(group, 3)).map((chunk: any) => ({
            isCard: true,
            cardList: chunk,
            rank: Math.min(...chunk.map((card: any) => card?.rank) ?? 999),
            level
          }));
        });

        return [...collectionCards.nonCards, ...collectionCardsSplitByLevel];
      })
      );
  }

  getBannerData(): Observable<Banner[]> {
    return this.commonService.getCustomization().pipe(first(), map(data => {
      const shopCustomization = JSON.parse(data?.data?.[0]?.shopCustomization ?? '[]')
      const transformedData = shopCustomization.map((x: any) => {
        return {
          prefix_text_underlined: '',
          post_text_underlined: x?.bannerTitle,
          image_url: x?.bannerImage
        }
      })
      if (!transformedData.length) return BannerDetails
      return transformedData
    }))
  }

  getShopLayout(): Observable<any> {
    return this.authApiService.getAndCache(location.origin + '/assets/json/landing-layout.json').pipe(first())
  }
}
